import React, { FC } from 'react';
import SEO from 'components/seo';
import Layout from 'containers/layout/layout';
import Header from 'containers/layout/header';
import Footer from 'containers/layout/footer';
import Hero from 'components/hero';
import WhitePaperDownloadForm from 'containers/whitePaperDownloadForm';

interface Props {
  location: string;
}

const TechAndTrustWhitepaperSignup: FC<Props> = ({ location }) => {
  return (
    <Layout location={location}>
      <SEO title="Download our infographic" />
      <Header />
      <main className="site-wrapper-reveal">
        <Hero title={'Tech\'s Trust Deficit'} hero="trust" />
        <WhitePaperDownloadForm
          title={'Tech and Trust Whitepaper Download'}
          formGroupId={'group[68204][8]'}
          journeyId={2284}
          stepId={16640}
          listId={'ba1454213b'}
        />
      </main>
      <Footer />
    </Layout>
  );
};

export default TechAndTrustWhitepaperSignup;
